/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { jsx } from "@emotion/core"
import { animated, useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"
import config from "../../config"

const HeroBook = ({ data }) => {
  const [isLinkActive, setLinkActive] = useState(false)
  const [isThumbActive, setThumbActive] = useState(false)
  const [isBookVisible, setBookVisible] = useState(true)

  const zoom = useSpring({
    transform: isLinkActive ? `scale(1.05)` : `scale(1)`,
  })

  const animationThumb = useSpring({
    transform: isThumbActive ? `scale(1.05)` : `scale(1)`,
  })

  const up = useSpring({
    to: [{ transform: "translateY(0)" }],
    from: { transform: "translateY(100px)" },
  })

  return (
    <div>
      <div
        className="relative"
        css={{
          height: "500px",
        }}
      >
        <div className="absolute h-full w-full overflow-hidden">
          <Img
            className="object-cover h-full w-full"
            fluid={data.parisSecret.childImageSharp.fluid}
          />
        </div>
        <div
          css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "block",
            top: "0",
            left: "0",
            backgroundColor: "rgba(28, 177, 233, 0.95);",
          }}
        ></div>
        <div className=" w-full h-full justify-start align-top flex flex-col">
          <h1 className="z-50 w-full text-5xl text-center text-white mt-20 font-heading">
            A Paris Secret
          </h1>
          <div className="z-50 flex flex-row justify-center mt-4">
            <a
              href={config.buylinks.parisLink}
              rel="nofollow noopener"
              target="_blank"
              className="inline-block text-white hover:text-black z-50"
              css={{
                transition: "0.7s all",
              }}
            >
              Buy now >
            </a>
          </div>
        </div>
      </div>
      <animated.div
        style={up}
        className="z-50 flex flex-row justify-center  -mt-64 mx-auto w-1/2 md:w-1/4 h-auto z-50"
      >
        <Waypoint
          key="animated-book-top"
          bottomOffset="10%"
          onEnter={() => setBookVisible(true)}
        />
        <animated.a
          href={config.buylinks.parisLink}
          rel="nofollow noopener"
          target="_blank"
          className="inline-block w-full h-full"
          style={animationThumb}
          onMouseEnter={() => setThumbActive(true)}
          onMouseLeave={() => setThumbActive(false)}
        >
          <Img
            className="object-cover z-50 w-full h-full"
            css={{
              boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
            }}
            fluid={data.parisSecretThumb.childImageSharp.fluid}
          />
        </animated.a>
      </animated.div>
      <animated.div
        style={up}
        className="max-w-3xl text-center mx-auto mt-16 text-xl md:text-2xl px-8 md:px-0  mb-8 md:mb-0 text-secondary font-semibold"
      >
        <p>A powerful promise. A terrible sacrifice. A second chance...</p>
      </animated.div>
      <animated.div
        style={up}
        className="hidden lg:block my-16 w-0 mx-auto border-r border-gray-100 h-16"
      ></animated.div>
      <div className="max-w-3xl text-center mx-auto px-8 md:px-0">
        <p className="mb-8 inline-block">
          <span className="font-semibold">1952</span>. In the fragile atmosphere
          of post-war Paris, Sophie Bernot is training as a heart surgeon. A
          young woman in a man's world, Sophie is determined to bury her past
          and forge her medical career, whatever the costs.
        </p>
        <p className="mb-8 inline-block">
          Across the channel, Sebastian Ogilvie is burning with ambition for his
          first architectural project. As his schemes lead him to France, and to
          a chance encounter with Sophie, his future seems full of promise.
        </p>
        <p className="mb-8 inline-block">
          But when Sophie and Sebastian find themselves entangled in a brief,
          passionate affair, they each face a choice that will change their
          lives irrevocably, and a secret that will take years to be
          uncovered... danger than ever before.
        </p>

        <p className="inline-block font-semibold">
          Sweeping from Paris to London, to the snow-capped peaks of the Alps,
          this is an unforgettable story of passion, heartache and forgiveness.
          Perfect for fans of Santa Montefiore and Lucinda Riley
        </p>
      </div>
      <div className="pb-20">
        <animated.div
          style={zoom}
          onMouseEnter={() => setLinkActive(true)}
          onMouseLeave={() => setLinkActive(false)}
          className="w-40 mt-20 h-40 text-center mx-auto flex justify-center align-middle flex-col text-white"
          css={{
            borderRadius: "50%",
            backgroundColor: "#1CB1E9",
          }}
        >
          <a
            href={config.buylinks.parisLink}
            rel="nofollow noopener"
            target="_blank"
            css={{
              display: "flex",
              flexFlow: "column",
              height: "100%",
              width: "100%",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <span className="inline-block">Buy now ></span>
          </a>
        </animated.div>
      </div>
    </div>
  )
}

export default HeroBook
